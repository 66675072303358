// Import polyfill from node_modules
// import 'core-js/modules/es6.set.js'
// import 'core-js/modules/es6.array.find-index.js'

(function() {

	// async function getData() {
	// 	const data1 = await promiseFn(1, 5000); 
	// 	const data2 = await promiseFn(2, 5000);
	// }
	// getData();


	
	var win = $(window),
		ww = win.innerWidth(),
		wh = win.innerHeight(),
		window_width = win.width(),
		ws = win.scrollTop();
	var resizeWindow = function(){
		ww = win.innerWidth();
		wh = win.innerHeight();
	}
	$(window)
		.on('load', function () {
			$('.index-banner').addClass('hover');
			IndexScroll();
			// var PcontPicMarkting = document.getElementById('PcontPicMarkting');
			// new Parallax(PcontPicMarkting);
			var PcontPicMarkting = document.getElementById('PcontPicMarkting');
			var PcontPicDesign = document.getElementById('PcontPicDesign');
			var PcontPicSystem = document.getElementById('PcontPicSystem');
			var SearchMouse = document.getElementById('searchmouse');
			var PcontMove1 = new Parallax(PcontPicMarkting,{
				invertX:!0,invertY:!0,frictionX:.06,frictionY:.06
			});
			var PcontMove2 = new Parallax(PcontPicDesign,{
				invertX:!0,invertY:!0,frictionX:.06,frictionY:.06
			});
			var PcontMove3 = new Parallax(PcontPicSystem,{
				invertX:!0,invertY:!0,frictionX:.06,frictionY:.06
			});
			var PcontMove4 = new Parallax(SearchMouse,{
				invertX:!0,invertY:!0,frictionX:.06,frictionY:.06
			});
			
		})
		.on('scroll', function () {
			setTimeout(() => {
				IndexScroll();
			}, 1000);
		})
		.on('resize', function () {
			IndexScroll();
		});
	// -----------------------------------
	// 頁面滾動時的動畫判斷
	// -----------------------------------
	function IndexScroll(){
		// 頁面滾動時的動畫判斷
		var $animation_elements = $('.js-animate');
		var window_height = win.height();
		var window_top_position = win.scrollTop();
		var window_bottom_position = (window_top_position + window_height - 250);

		$.each($animation_elements, function() {
			var $element = $(this);
			var element_height = $element.outerHeight();
			var element_top_position = $element.offset().top;
			var element_bottom_position = (element_top_position + element_height);

			//check to see if this current container is within viewport
			if ((element_bottom_position >= window_top_position) && (element_top_position <= window_bottom_position)) {
				$element.addClass('hover');
			}else{
				// $element.removeClass('hover');
			}
		});
	}

})();

// var rellax = new Rellax('.pro_test1', {horizontal: true});
// $(document).on('ready',function(){
// });/* end document */


var ShareCard = new Swiper('.swiper-container.worksslider', {
	// speed: 1200,
	// 	autoplay: {
	// 	delay: 4000,
	// },
	centeredSlides: false,
	loop: false, //循環撥放
	spaceBetween: 20,
	slidesPerGroup: 1,
	slidesPerView: 3,
	effect: "slide", //Could be "slide", "fade", "cube", "coverflow" or "flip"
	navigation: {
		nextEl: '.worksarrow.right',
		prevEl: '.worksarrow.left',
	},
	pagination: {
	  el: '.swiper-container.worksslider .swiper-pagination',
	  // dynamicBullets: true,
	  clickable: true,
	},
	breakpoints: {
		0: {
			simulateTouch: true,
			spaceBetween: 0,
			slidesPerView: 1
		},
		640: {
			simulateTouch: true,
			spaceBetween: 20,
			slidesPerView: 2
		},
		1025: {
			simulateTouch: false,
			spaceBetween: 20,
			slidesPerView: 2
		},
		1201: {
			simulateTouch: false,
			spaceBetween: 30,
			slidesPerView: 3
		},
		1399: {
			simulateTouch: false,
			spaceBetween: 30,
			slidesPerView: 3
		},
		5000: {
			simulateTouch: false,
			spaceBetween: 30,
			slidesPerView: 3
		}
	}
});


var ShareCard = new Swiper('.swiper-container.newsslider', {
	// speed: 1200,
	// 	autoplay: {
	// 	delay: 4000,
	// },
	centeredSlides: false,
	loop: false, //循環撥放
	spaceBetween: 20,
	slidesPerGroup: 1,
	slidesPerView: 1,
	effect: "slide", //Could be "slide", "fade", "cube", "coverflow" or "flip"
	navigation: {
		nextEl: '.newsarrow.right',
		prevEl: '.newsarrow.left',
	},
	pagination: {
	  el: '.swiper-container.newsslider .swiper-pagination',
	  // dynamicBullets: true,
	  clickable: true,
	},
});
